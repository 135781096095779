function Welcome() {
  return (
    <>
      <h1>This service is unfortunately not available anymore</h1>
      <p>
        But you're more than welcome to{' '}
        <a href="https://www.bokadirekt.se/places/no-ordinary-scent-57297">
          book a workshop
        </a>
      </p>
      <p>
        <a href="https://nosworkshop.se/">Or go to our homepage</a>
      </p>
    </>
  );
}

export default Welcome;

/*
import { motion, useInView } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import doubleArrow from '../assets/icons/doubleArrow.svg';

type Props = {windowWidth: number, orientation: string};

function Welcome({ windowWidth, orientation }: Props) {
  useEffect(() => {
    if (windowWidth >= 768) {
      document.querySelector('.app')!.removeAttribute('style');
    }
  }, [windowWidth]);

  const personalImages = useRef(null);
  const mixingBlends = useRef(null);
  const personalImagesInView = useInView(personalImages, { amount: 0.75 });
  const mixingBlendsInView = useInView(mixingBlends, { amount: 0.75 });

  useEffect(() => {
    if (
      windowWidth < 1024 &&
      orientation === 'PORTRAIT' &&
      personalImagesInView
    ) {
      // document.querySelector('.app').style.backgroundColor = '#ecfff3';
      document.querySelector('.app')!.setAttribute('style', 'background-color: #ecfff3');
    }
  }, [personalImagesInView, windowWidth, orientation]);

  useEffect(() => {
    if (
      windowWidth < 1024 &&
      orientation === 'PORTRAIT' &&
      mixingBlendsInView
    ) {
      document.querySelector('.app')!.setAttribute('style', 'background-color: #f19101');
      // .style.backgroundColor = '#f19101';
    }
  }, [mixingBlendsInView, windowWidth, orientation]);

  useEffect(
    () => () => {
      document.querySelector('.app')!.removeAttribute('style');
    },
    []
  );

  return (
    <>
      <motion.div className='flex-column section-small top-space'>
        <div className='flex-column'>
          <h1 className='title'>Welcome to the lab</h1>
          <h2 className='subtitle'>Create scent by...</h2>
        </div>
        {windowWidth < 1366 && (
          <img
            style={windowWidth >= 768 ? { marginTop: '32px' } : {}}
            src={doubleArrow}
            alt=''
          />
        )}
      </motion.div>

      <div className='options'>
        <div className='option__wrapper'>
          <Link to='upload'>
            <div ref={personalImages} className='outline'>
              <div className='headline flex-column' data-color='mint'>
                <div className='flex-row'>
                  <span className='tag'>01</span>
                  <h3 className='option'>Personal images</h3>
                </div>
                {windowWidth >= 768 && (
                  <p className='optionText'>
                    Upload 1-3 personal images and our AI-robot will create a
                    unique scent based on your images.
                  </p>
                )}
              </div>
            </div>
          </Link>
          {windowWidth < 768 && (
            <p className='optionText'>
              Upload 1-3 personal images and our AI-robot will create a unique
              scent based on your images.
            </p>
          )}
        </div>
        <div className='option__wrapper'>
          <Link to='mixingblends'>
            <div ref={mixingBlends} className='outline'>
              <div className='headline flex-column' data-color='brown'>
                <div className='flex-row'>
                  <span className='tag'>02</span>
                  <h3 className='option'>Mixing blends</h3>
                </div>

                {windowWidth >= 768 && (
                  <p className='optionText'>
                    Create a unique scent by combining 1 base with 1 accord
                    to create your unique perfume.{' '}
                  </p>
                )}
              </div>
            </div>
          </Link>
          {windowWidth < 768 && (
            <p className='optionText'>
              Create a unique scent by combining 1 base with 1 accord to
              create your unique perfume.{' '}
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default Welcome;
*/
