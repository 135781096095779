import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import {
  BrandFormContext,
  IBrandFormContext,
} from '../../contexts/BrandFormContext';
import { BrandFormBox } from '../../scss/theme';

const BrandCore: React.FC = () => {
  const { brandCore, setBrandCore } =
    useContext<IBrandFormContext>(BrandFormContext);
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate('/brandscent/emotions');
  };

  return (
    <Container maxWidth="sm">
      <Box sx={BrandFormBox}>
        <form onSubmit={handleSubmit} className="brand-form">
          <Typography variant="subtitle2" gutterBottom>
            WHAT SETS YOUR BRAND APART?
          </Typography>
          <Typography variant="h2">
            Describe your brand's core using three key words.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <b>For example:</b> bold, innovative, aspirational.
          </Typography>
          <TextField
            label="Brand core"
            value={brandCore}
            onChange={(e) => {
              setBrandCore(e.target.value);
            }}
            fullWidth
            autoFocus
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            disabled={!brandCore}
            color="primary"
            style={{ marginTop: '20px' }}
          >
            Continue
          </Button>
        </form>
      </Box>
    </Container>
  );
};
export default BrandCore;
