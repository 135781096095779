export const pageVariants = {
  initial: {
    opacity: 0,
    y: '100vh',
  },
  in: {
    opacity: 1,
    y: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    y: '-100vh',
    transition: {
      duration: 2,
    },
  },
};

export const fadeItem = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    /*     transition: {
      ease: 'linear',
      duration: 2,
      delay: 1,
    }, */
  },
};

export const animateBlends = {
  show: {
    opacity: 1,
    transition: {
      ease: 'linear',
      duration: 0.15,
      staggerChildren: 0.1,
    },
  },
};

export const rowContainer = {
  hidden: { opacity: 0, height: '0px' },
  show: {
    opacity: 1,
    height: 'auto',
    transition: {
      ease: 'linear',
      duration: 0.3,
      staggerChildren: 0.2,
    },
  },
};

export const rowItem = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.6,
    },
  },
};

export const dropdownContainer = {
  /*   hidden: { opacity: 0 }, */
  show: {
    /*     opacity: 1, */
    transition: {
      ease: 'linear',
      staggerChildren: 0.05,
    },
  },
  /*   exit: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  }, */
};

export const dropdownItem = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
  /*   exit: {
    opacity: 0,
    transition: {
      duration: 0.05,
    },
  }, */
};

export const tagContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      ease: 'linear',
      staggerChildren: 0.3,
    },
  },
};

export const tagItem = {
  hidden: { opacity: 0, scale: 0.9 },
  show: { opacity: 1, scale: 1 },
};
