import React from 'react';
import '../scss/pages/Results/intensitymeter.scss';

function IntensityMeter({ strength, max = 5 }: { strength: number, max?: number }) {
  if(strength > max || strength < 0) return <></>
  return (
    <>
        {typeof strength === 'number' && <>
        <div id='intensity__wrapper'>
          <span>Intensity: </span>
          {[...Array(strength)].map((e, i) => <div className='ball filled' key={`filled${i}`} />)}
          {[...Array(max - strength)].map((e, i) => <div className='ball' key={i} />)}
        </div>
        </>}
    </>
  );
}

export default IntensityMeter;