import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
import { pageVariants } from '../util/animations';

function Layout({ header }: { header: ReactNode }) {
  return (
    <>
      {header}
      <motion.main
        layout
        initial="initial"
        animate="in"
        exit="exit"
        variants={pageVariants}
        className="main"
      >
        <Outlet />
      </motion.main>
    </>
  );
}

export default Layout;
