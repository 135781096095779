import { motion } from 'framer-motion';
import { tagContainer, tagItem } from '../util/animations';
import React from 'react';

export function Tags({ tags }: {tags: string[]}) {
  let selectedTags = [...tags];
  if (tags.length > 4) {
    selectedTags = tags.slice(0, 4);
  }

  return (
    <motion.div
      variants={tagContainer}
      initial='hidden'
      animate='show'
      className='flex-row'
      style={{ paddingBottom: '32px' }}
    >
      {(selectedTags || tags).map((tag) => (
        <motion.span
          variants={tagItem}
          key={tag}
          className='tag-headline border-round'
        >
          {tag}
        </motion.span>
      ))}
    </motion.div>
  );
}
