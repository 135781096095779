import React from 'react';
import bottle from '../assets/solo_product_w_background_1024@2x.jpg';

interface Props {
  customerName?: string;
  perfumeName?: string;
}

function Bottle({ customerName, perfumeName }: Props) {

  return (
    <div className='bottleWrapper__container'>
      <div className='bottleWrapper'>
        <img className='bottle-image' src={bottle} alt='Bottle Preview' />
        <span className='customerName'>{customerName}</span>
        <span className='perfumeName'>{perfumeName}</span>
      </div>
    </div>
  );
}

export default Bottle;
