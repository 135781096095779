import React from 'react';
import shortLogo from '../assets/icons/NOS-logo.svg';
import cart from '../assets/icons/cart.svg';
import account from '../assets/icons/account.svg';

function Header() {
  return (
    <header className="header">
      <a href="https://nosworkshop.se/">
        <img
          src={shortLogo}
          height={42}
          alt="NOS Emotiontech Logo"
          title="NOS Emotiontech Logo"
          className="logo"
        />
      </a>
      <div className="headerMenu">
        <a href="https://nosworkshop.se/account/login">
          <img src={account} alt="Account" />
        </a>

        <a href="https://nosworkshop.se/cart">
          <img src={cart} alt="Cart" />
        </a>
      </div>
    </header>
  );
}

export default Header;
