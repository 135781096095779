import React, { useState } from 'react';
import plus from '../assets/icons/plus.svg';
import cross from '../assets/icons/cross.svg';

function UploadSlot({ id, setSelectedFiles }) {
  const [currentlySelectedFile, setCurrentlySelectedFile] = useState(null);

  return (
    <label htmlFor={id}>
      <input
        type="file"
        className="visually-hidden"
        id={id}
        accept="image/jpg, image/png, image/jpeg, image/webp, image/gif"
        onChange={(e) => {
          setSelectedFiles(currentlySelectedFile, e.target.files[0]);
          setCurrentlySelectedFile(e.target.files[0]);
        }}
      />
      <div className={`uploadSlot ${currentlySelectedFile ? 'selected' : ''}`}>
        {currentlySelectedFile ? (
          <div className="imgWrapper">
            <img
              className="current-selected"
              src={URL.createObjectURL(currentlySelectedFile)}
              alt="add"
            />
            <div
              role="button"
              onClick={(e) => {
                e.preventDefault();
                setSelectedFiles(currentlySelectedFile);
                setCurrentlySelectedFile(null);
              }}
              type="button"
              className="remove"
            >
              <img src={cross} alt="Remove" />
            </div>
          </div>
        ) : (
          <img src={plus} alt="add" />
        )}
      </div>
    </label>
  );
}
export default UploadSlot;
