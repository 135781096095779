import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { BrandFormContext } from '../../contexts/BrandFormContext';
import { BrandFormBox } from '../../scss/theme';

const BrandPersonName: React.FC = () => {
  const { personName, setPersonName } = useContext(BrandFormContext);
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/brandscent/name/');
  };

  return (
    <Container maxWidth="md">
      <Box sx={BrandFormBox}>
        <form onSubmit={handleSubmit} className="brand-form">
          <Typography variant="subtitle2" gutterBottom>
            INTRODUCTION
          </Typography>
          <Typography variant="h2" gutterBottom>
            May we have your name?
          </Typography>
          <TextField
            label="Name"
            value={personName}
            onChange={(e) => {
              setPersonName(e.target.value);
            }}
            fullWidth
            autoFocus
            margin="normal"
          />
          <Button
            type="submit"
            disabled={!personName}
            variant="contained"
            color="primary"
            sx={{ marginTop: '20px' }}
          >
            Continue
          </Button>
        </form>
      </Box>
    </Container>
  );
};
export default BrandPersonName;
