import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import NavigationBar from '../../components/NavigationBar';
import ScentDescription from '../../components/ScentDescription';
import { getBlendsInStock } from '../../api/api';
import { Tags } from '../../components/Tags';
import downArrow from '../../assets/icons/downArrow.svg';
import { EventFormContext } from '../../contexts/EventFormContext';

function Results({ windowWidth }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [blendIndex, setBlendIndex] = useState({});
  const [intensity, setIntensity] = useState(0);
  const [prevPage] = useState(location?.state?.referrer);
  const { localUrls, tags, characteristics, recipe } =
    useContext(EventFormContext);

  useEffect(() => {
    getBlendsInStock().then((response) =>
      setBlendIndex({ ...response.base, ...response.accord })
    );
  }, []);
  const getBaseAndAccord = (recipeString) => {
    let base;
    let accord;

    const recipe = recipeString;
    base = recipe
      .substring(recipe.indexOf('B'), recipe.indexOf('B') + 3)
      .trim();
    accord = recipe.substring(0, recipe.indexOf(',')).trim();

    return { base, accord };
  };

  useEffect(() => {
    if (!Object.keys(blendIndex).length > 0) return;

    function calculateIntensity() {
      return Math.round(
        0.63 * blendIndex[getBaseAndAccord(recipe).base]['Intensity'] +
          0.37 * blendIndex[getBaseAndAccord(recipe).accord]['Intensity']
      );
    }

    setIntensity(calculateIntensity());
  }, [blendIndex]);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <NavigationBar pageCount="2/4" />
      {prevPage !== 'mixingblends' && (
        <div className="page-section">
          <div style={{ paddingBottom: '16px' }} className="flex-column">
            <h1 className="title">This is the AI analysis</h1>
            <h2 className="subtitle">from your images</h2>
          </div>

          <div className="flex-column section-medium">
            {localUrls?.length ? (
              <div className="flex-row">
                {localUrls.map((file) => (
                  <div
                    key={
                      typeof localUrls[0] === 'string'
                        ? file
                        : file.lastModified
                    }
                    className="uploadSlot"
                  >
                    <div className="imgWrapper">
                      <img
                        className="cover-container"
                        src={
                          typeof localUrls[0] === 'string'
                            ? file
                            : URL.createObjectURL(file)
                        }
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            <div className="flex-column section-small">
              {tags ? (
                <>
                  <span className="tag-headline">
                    Tags generated from images
                  </span>
                  <Tags tags={tags} />
                </>
              ) : (
                <motion.span
                  initial={{ opacity: 0.1 }}
                  animate={{
                    opacity: 1,
                    transition: {
                      repeat: Infinity,
                      duration: 0.8,
                      repeatType: 'reverse',
                    },
                  }}
                >
                  Analyzing your images...
                </motion.span>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={` ${
          windowWidth >= 768 ? '' : 'flex-column full-width section-medium'
        }`}
      >
        {characteristics && (
          <>
            <div className="flex-column">
              <h1 className="title">Your personal scent</h1>
              <h2 className="subtitle">composition</h2>
              <span style={{ paddingTop: '12px' }}>
                View your scent notes in step 4
              </span>
            </div>
            <div
              style={
                windowWidth >= 768
                  ? { width: '1065px', maxWidth: '80vw', paddingBottom: '64px' }
                  : null
              }
              className={
                windowWidth < 768 ? 'flex-column full-width' : 'flex-row'
              }
            >
              <ScentDescription
                base={getBaseAndAccord(recipe).base}
                accord={getBaseAndAccord(recipe).accord}
                characteristics={characteristics}
                blendIndex={blendIndex}
                intensity={intensity}
              />
            </div>
          </>
        )}
      </div>

      {characteristics && (
        <div className="bottom-page-overlay">
          <button
            className="btn continue-btn"
            onClick={() => {
              const pos =
                document.documentElement.scrollTop /
                (document.documentElement.scrollHeight -
                  document.documentElement.clientHeight);

              if (
                pos > 0.4 ||
                document.documentElement.scrollHeight -
                  document.documentElement.clientHeight <
                  64
              ) {
                navigate('../bottlepreview');
              } else {
                window.scrollBy({
                  top: window.innerHeight,
                  left: 0,
                  behavior: 'smooth',
                });
              }
            }}
          >
            Let&apos;s name it
          </button>
          <button className="scroll-down-button" onClick={scrollToBottom}>
            <img src={downArrow} alt="Arrow pointing down" />
          </button>
        </div>
      )}
    </>
  );
}

export default Results;
