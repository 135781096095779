import React from 'react';
import UploadSlot from './UploadSlot';

function UploadArea({setSelectedFiles}) {
  return (
    <div className="flex-row upload-area">
      <UploadSlot setSelectedFiles={setSelectedFiles} id={1} />
      <UploadSlot setSelectedFiles={setSelectedFiles} id={2}/>
      <UploadSlot setSelectedFiles={setSelectedFiles} id={3}/>
    </div>
  );
}

export default UploadArea;
