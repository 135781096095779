import { Box, Container, Link, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getOlfactives } from '../../api/api';
import { topSixCategories } from '../../util/calculations';
import { AnimatePresence, motion } from 'framer-motion';
import { BrandFormBox } from '../../scss/theme';
import NosVideo from '../../assets/nos.mp4';
import {
  BrandFormContext,
  IBrandFormContext,
} from '../../contexts/BrandFormContext';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

interface UrlItem {
  url: string;
  value: number;
  displayName: string;
}

const BrandThanks: React.FC = () => {
  const [olfactives, setOlfactives] = useState<object[]>([]);
  const [urls, setUrls] = useState<UrlItem[]>([]);
  const imageSize = 200;
  const radius = 250;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { recipe } = useContext<IBrandFormContext>(BrandFormContext);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getOlfactives().then((json) => {
      if (typeof json === 'object') {
        // @ts-ignore
        setOlfactives(json);
      }
    });
  }, []);

  useEffect(() => {
    if (!olfactives) return;
    const combined = [
      ...Object.keys(recipe.base.characteristics),
      ...Object.keys(recipe.accord.characteristics),
    ].reduce((acc, key) => {
      return {
        ...acc,
        [key]:
          (recipe.base.characteristics[key] || 0) * 0.63 +
          (recipe.accord.characteristics[key] || 0) * 0.37,
      };
    }, {});
    const topSix = Object.keys(topSixCategories(combined));

    const totalValue = topSix.reduce(
      (total, olf) => total + Math.pow(combined[olf], 2),
      0
    );

    setUrls(
      topSix.map((olf: string) => {
        return {
          url: olfactives[olf]?.image[0]?.thumbnails?.large?.url,
          value: combined[olf],
          displayName: olf,
        };
      })
    );
  }, [olfactives, recipe]);

  return (
    <AnimatePresence>
      <Container>
        <Box sx={BrandFormBox}>
          <Typography variant="h2" gutterBottom>
            THANK YOU!
          </Typography>
          <Typography variant="subtitle1">
            Your responses have been successfully submitted. We will be in touch
            soon with your EmotionTech™️ analysis results. In the meantime,
            here’s a sneak peek of your brand’s olfactive profile.
          </Typography>
          {screenWidth > 817 && (
            <div
              style={{
                position: 'relative',
                height: radius * 2 + imageSize,
                width: radius * 2 + imageSize,
              }}
            >
              {urls.map((item, index) => {
                const angle =
                  ((urls.length - 1 - index * 0.95) / urls.length) *
                  2 *
                  Math.PI;
                const x = radius * (1 + Math.cos(angle));
                const y = radius * (1 + Math.sin(angle));

                const size = imageSize * (1 - index / (2 * (urls.length - 1)));

                return (
                  <div
                    key={index}
                    style={{
                      position: 'absolute',
                      top: y,
                      left: x,
                      height: size,
                      width: size,
                      overflow: 'hidden',
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 0.7 }}
                      whileHover={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          zIndex: 1,
                          marginRight: '10px',
                          color: 'gray',
                        }}
                      >
                        <Typography variant="body2">
                          {item.displayName}&nbsp;{Math.round(item.value * 100)}
                          %
                        </Typography>
                      </div>
                      <motion.img
                        src={item.url}
                        alt=""
                        style={{
                          height: '100%',
                          width: '100%',
                        }}
                        initial={{ scale: 0, opacity: 0.5 }}
                        animate={{ scale: 0.9, opacity: 1 }}
                        whileHover={{
                          scale: 1.1,
                          opacity: 1,
                          transition: { duration: 0.3 },
                        }}
                        transition={{
                          ease: [0.43, 0.13, 0.23, 0.96],
                          duration: 1.5,
                          delay: index * 0.5,
                        }}
                      />
                    </motion.div>
                  </div>
                );
              })}
            </div>
          )}
          {screenWidth <= 817 && (
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <KeyboardDoubleArrowDownIcon />
              {urls.map((urlItem, index) => {
                // Adjust the scale factor calculation to make the last image slightly larger
                const scaleFactor = 0.6 + (0.4 - index / (2 * urls.length));

                return (
                  <li
                    key={index}
                    style={{
                      listStyleType: 'none',
                      marginBottom: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={urlItem.url}
                      alt={urlItem.displayName}
                      // Apply the scale factor to the image width
                      style={{ width: `${scaleFactor * 100}%` }}
                    />
                    <Typography
                      variant="body2"
                      sx={{ color: 'gray', textAlign: 'center' }}
                    >
                      {urlItem.displayName} {Math.round(urlItem.value * 100)}%
                    </Typography>
                  </li>
                );
              })}
            </ul>
          )}
          <div style={{ marginTop: '20rem' }}>
            <Link href="https://nosemotiontech.com/" color="primary">
              <Typography variant="body1">
                Meanwhile, go to our website
              </Typography>
            </Link>
            <motion.div
              initial={{
                opacity: 0,
              }}
              whileInView={{ opacity: 1, y: '0', transition: { duration: 3 } }}
              viewport={{ once: true }}
            >
              <video loop autoPlay muted width="100%">
                <source src={NosVideo} type="video/mp4" />
              </video>
            </motion.div>
          </div>
        </Box>
      </Container>
    </AnimatePresence>
  );
};

export default BrandThanks;
