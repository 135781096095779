import { createContext, ReactNode, useState } from 'react';
import axios from 'axios';
import {
  checkIfUserExistsInPipedrive,
  createUserInPipedrive,
  uploadFilesToPipedrive,
} from '../api/pipedrive-api';

export interface Blend {
  id: string;
  blendID: string;
  blendName: string;
  position: string;
  baseDisplayName?: string;
  heartDisplayName?: string;
  topDisplayName?: string;
  matches?: string[];
  tags?: string[];
  blendDescription?: string;
  intensity?: number;
  linkToOlfactiveFamily: string[];
  b2bClassification?: string[];
  characteristics: {
    ambery?: number;
    woody?: number;
    musk?: number;
    spicy?: number;
    green?: number;
    floral?: number;
  };
}

export interface Recipe {
  base: Blend;
  accord: Blend;
}

export interface IBrandFormContext {
  personName: string;
  brandName: string;
  brandCore: string;
  emotions: string[];
  personality: string;
  time: string;
  physical: string;
  colors: string[];
  images: Blob[];
  imageUrlsToCloudinary: string[];
  email: string;
  recipe: Recipe;
  setPersonName: (name: string) => void;
  setBrandName: (name: string) => void;
  setBrandCore: (core: string) => void;
  setEmotions: (emotions: string[]) => void;
  setPersonality: (personality: string) => void;
  setTime: (time: string) => void;
  setPhysical: (physical: string) => void;
  setColors: (colors: string[]) => void;
  setImages: (images: Blob[]) => void;
  setImageUrlsToCloudinary: (imageUrls: string[]) => void;
  setEmail: (email: string) => void;
  setRecipe: (recipe: Recipe) => void;
  submitData: () => Promise<void>;
}

const BrandFormContext = createContext<IBrandFormContext>({
  personName: '',
  brandName: '',
  brandCore: '',
  emotions: [],
  personality: '',
  time: '',
  physical: '',
  colors: [],
  images: [],
  imageUrlsToCloudinary: [],
  email: '',
  recipe: {} as Recipe,
  setPersonName: () => {},
  setBrandName: () => {},
  setBrandCore: () => {},
  setEmotions: () => {},
  setPersonality: () => {},
  setTime: () => {},
  setPhysical: () => {},
  setColors: () => {},
  setImages: () => {},
  setImageUrlsToCloudinary: () => {},
  setEmail: () => {},
  setRecipe: () => {},
  submitData: async () => {},
});
const BrandFormProvider = ({ children }: { children: ReactNode }) => {
  const [personName, setPersonName] = useState<string>('');
  const [brandName, setBrandName] = useState<string>('');
  const [brandCore, setBrandCore] = useState<string>('');
  const [emotions, setEmotions] = useState<string[]>([]);
  const [personality, setPersonality] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [imageUrlsToCloudinary, setImageUrlsToCloudinary] = useState<string[]>(
    []
  );
  const [physical, setPhysical] = useState<string>('');
  const [colors, setColors] = useState<string[]>([]);
  const [images, setImages] = useState<Blob[]>([]);
  const [email, setEmail] = useState<string>('');
  const [recipe, setRecipe] = useState<Recipe>({} as Recipe);

  const submitData = async () => {
    // Check if user exists in Pipedrive, if not create a new user to add to the deal
    let userId = await checkIfUserExistsInPipedrive(email);
    if (!userId) {
      userId = await createUserInPipedrive(personName, email);
    }

    // Create a new deal in Pipedrive, and afterwards upload the images to the deal
    try {
      const data = {
        title: brandName,
        person_id: userId,
        brandCore: brandCore,
        emotions: emotions.join(', '),
        personality: personality,
        brandTime: time,
        physical: physical,
        colors: colors.join(', '),
        imageUrlsToCloudinary: imageUrlsToCloudinary,
        recipe: JSON.stringify(recipe),
      };
      const response = await axios.post(
        'https://no-ordinary-scent-api.herokuapp.com/v1/create-pipedrive-deal',
        data
      );
      await uploadFilesToPipedrive(images, response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BrandFormContext.Provider
      value={{
        personName,
        brandName,
        brandCore,
        emotions,
        personality,
        time,
        physical,
        colors,
        images,
        imageUrlsToCloudinary,
        email,
        recipe,
        setPersonName,
        setBrandName,
        setBrandCore,
        setEmotions,
        setPersonality,
        setTime,
        setPhysical,
        setColors,
        setImages,
        setImageUrlsToCloudinary,
        setEmail,
        setRecipe,
        submitData,
      }}
    >
      {children}
    </BrandFormContext.Provider>
  );
};

export { BrandFormContext, BrandFormProvider };
