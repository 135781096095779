
export const orderTemplate = {
  orderId: '31340782977103',
  recipe: null,
  customerName: '',
  perfumeName: '',
  selectedFiles: []
};

export type Order = {
  orderId: string;
  recipe: any;
  customerName: string;
  perfumeName: string;
  selectedFiles?: Blob[];
  email?: string;
}
