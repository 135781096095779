import React, { useEffect, useRef, useState } from 'react';
import '../scss/pages/Results/scentdescription.scss';
import IntensityMeter from './IntensityMeter';
import { Characteristics, topSixCategories } from '../util/calculations';
import { getOlfactives } from '../api/api';
import Loader from './Loader';
import { useInView } from 'framer-motion';

const ImageCloud = ({
  olfactives,
  characteristics,
}: {
  olfactives: {
    image: object;
    [index: string]: any;
  };
  characteristics: any;
}) => {
  const cloud = useRef(null);
  const cloudInView = useInView(cloud, { amount: 0.6 });
  const topSix = Object.keys(topSixCategories(characteristics));
  const urls = [
    ...topSix.map((olf) => olfactives[olf]?.image[0].thumbnails.large.url),
  ];
  const dimensions = (index: number) => {
    return 80 + 90 * topSixCategories(characteristics)[topSix[index]];
  };

  const images = urls.map((f, index) => (
    <div
      className={`imagecloud__image-wrapper`}
      key={index}
      style={{
        top: '50%',
        left: '50%',
        width: dimensions(index),
        height: dimensions(index),
        transitionDelay: `${index * 50}ms`,
      }}
    >
      <img src={f} />
      <div className="olfactive-family__label">{`${topSix[index]} ${(
        topSixCategories(characteristics)[topSix[index]] * 100
      ).toFixed(0)}%`}</div>
    </div>
  ));

  useEffect(() => {
    if (cloudInView)
      document
        .querySelectorAll('.imagecloud__image-wrapper')
        .forEach((div, index) => {
          div.classList.add(`iw-${index}`);
        });
  }, [cloudInView]);

  return (
    <div id="imagecloud__container" ref={cloud}>
      {images}
    </div>
  );
};

function ScentDescription({
  intensity,
  characteristics,
  base,
  accord,
  blendIndex,
}: {
  blendIndex: any;
  baseDescription: string;
  base: string;
  accord: string;
  intensity: number;
  characteristics: Characteristics;
}) {
  const [olfactives, setOlfactives] = useState();
  const [description, setDescription] = useState({ base: '', accord: '' });
  const [selectedOlfactiveFamilies, setSelectedOlfactiveFamilies] = useState({
    base: '',
    accord: '',
  });

  useEffect(() => {
    getOlfactives().then((json) => {
      if (typeof json === 'object') {
        // @ts-ignore
        setOlfactives(json);
      }
    });

    if (Object.keys(blendIndex).length > 3 && base) {
      setDescription({
        base: blendIndex[base]['Blend description'],
        accord: blendIndex[accord]['Blend description'],
      });

      setSelectedOlfactiveFamilies({
        base: blendIndex[base]['Olfactive Family'],
        accord: blendIndex[accord]['Olfactive Family'],
      });
    }
  }, [blendIndex]);

  return olfactives && characteristics ? (
    <section>
      <ImageCloud olfactives={olfactives} characteristics={characteristics} />
      <div>
        <h4>
          Olfactive family: {selectedOlfactiveFamilies.base} /{' '}
          {selectedOlfactiveFamilies.accord}
        </h4>
        <hr />
        <span>Description</span>
        <div>{description?.accord}</div>
        <div>{description?.base}</div>
        <hr />
        <IntensityMeter strength={intensity} />
      </div>
    </section>
  ) : (
    <div
      className="flex-row"
      style={{ width: '100%', justifyContent: 'center', padding: '16px' }}
    >
      <Loader />
    </div>
  );
}

export default ScentDescription;
