function Error() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '15dvh',
      }}
    >
      <h1>Sorry,</h1>
      <p>Something went wrong..</p>
    </div>
  );
}
export default Error;
