import axios from 'axios';
import { compressFile } from '../pages/event/Upload';

export const checkIfUserExistsInPipedrive = async (email: string) => {
  try {
    const response = await axios.get(
      `https://no-ordinary-scent-api.herokuapp.com/v1/search-pipedrive-user?term=${encodeURIComponent(
        email
      )}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const createUserInPipedrive = async (
  personName: string,
  email: string
) => {
  let userData = {
    name: personName,
    email: email,
  };

  try {
    const response = await axios.post(
      `https://no-ordinary-scent-api.herokuapp.com/v1/create-pipedrive-user`,
      userData
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const uploadFilesToPipedrive = async (
  images: Blob[],
  dealId: number
) => {
  const formData = new FormData();
  try {
    const promises = images!.map((blob) => compressFile(blob));
    const compressedFiles = await Promise.all(promises);
    compressedFiles.forEach((f) => {
      formData.append('files', f);
    });
    await axios.post(
      `https://no-ordinary-scent-api.herokuapp.com/v1/upload-files-to-pipedrive?dealid=${dealId}`,
      formData
    );
  } catch (error) {
    console.error(error);
  }
};
