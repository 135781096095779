import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

type InputFieldProps = {
  setter: (value: string) => void;
  text: string;
  windowWidth: number;
  maxLength?: number;
}

function InputField({ setter, text, windowWidth, maxLength }: InputFieldProps) {
  function handleKeyPress(e: any) {
    if (e.key === 'Enter') {
      const submitButton = document.querySelector('.submit')! as HTMLElement;
      submitButton.click();
    }
  }

  useEffect(() => {
    document.querySelector('input')!.focus();
  }, []);

  return (
    <motion.input
      animate={{ opacity: 1 }}
      exit={{ x: -10, opacity: 0 }}
      transition={{ duration: 0.3 }}
      size={windowWidth >= 768 ? 24 : 16}
      onChange={(e) => {
        setter(e.target.value);
      }}
      onKeyDown={(e) => {
        handleKeyPress(e);
      }}
      type='text'
      spellCheck='false'
      placeholder={text || ''}
      maxLength={maxLength || 13}
      aria-placeholder={text || ''}
    />
  );
}

export default InputField;
