import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Compressor from 'compressorjs';
import NavigationBar from '../../components/NavigationBar';
import UploadArea from '../../components/UploadArea';
import TermsAndConditions from '../../components/TermsAndConditions';
import removeDuplicateTags from '../../util/tags';
import Loader from '../../components/Loader';
import { getRecipe } from '../../api/api';
import { EventFormContext } from '../../contexts/EventFormContext';

const CLOUDINARY_MAX_UPLOAD_SIZE = 20971520;

export function compressFile(blob: Blob): Promise<File> {
  return new Promise((resolve, reject) => {
    new Compressor(blob, {
      quality: 0.6,
      success(result: File) {
        if (result.size < CLOUDINARY_MAX_UPLOAD_SIZE) {
          resolve(
            new File([result], result.name, {
              type: result.type,
            })
          );
        } else {
          reject(new Error(`Image file (${result.name}) size too big.`));
        }
      },
      error(err) {
        reject(err);
      },
    });
  });
}

const Upload: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loadState, setLoadState] = useState(false);
  const {
    setImages,
    images,
    setLocalUrls,
    setRemoteUrls,
    setCharacteristics,
    setRecipe,
    setTags,
  } = useContext(EventFormContext);

  function handleFilesSelected(prevFile: Blob, newFile: Blob) {
    const arr = [...images!.filter((file) => file !== prevFile)];
    const files = newFile ? [...arr, newFile] : [...arr];
    const localUrls = newFile
      ? [...arr, newFile].map((f) => URL.createObjectURL(f))
      : arr.map((f) => URL.createObjectURL(f));

    setImages(files);
    setLocalUrls(localUrls);
  }

  const onUpload = async () => {
    setError('');
    setLoadState(true);

    type Record = { _url: string };

    type imageProp = `_image ${string}`;
    const remoteUrls: { [index: imageProp]: string } = {};
    const records: Record[] = [];
    const formData = new FormData();

    try {
      const promises = images.map((blob) => compressFile(blob));
      const compressedFiles = await Promise.all(promises);
      compressedFiles.forEach((f) => {
        formData.append('files', f);
      });
      const imageUploadRequest = {
        url: 'https://no-ordinary-scent-api.herokuapp.com/v1/upload_images',
        method: 'POST',
        data: formData,
      };

      const { data } = await axios(imageUploadRequest);
      data.forEach((url: string, index: number) => {
        remoteUrls[`_image ${index + 1}`] = url;
        records.push({ _url: url });
      });
    } catch (e) {
      setImages([]);
      setLocalUrls([]);
      let msg = e instanceof Error ? e.message : '';

      throw new Error(
        msg + ' (Supported image formats: .jpg, .png, .gif, .webp).',
        { cause: e }
      );
    }

    try {
      const recipeData = await getRecipe(records);

      setCharacteristics(recipeData.characteristics);
      setRecipe(recipeData.recipe);
      setRemoteUrls(Object.values(remoteUrls).join(', '));
      setTags(removeDuplicateTags(recipeData.baseTags, recipeData.accordTags));

      window.sessionStorage.setItem('recipeData', JSON.stringify(recipeData));
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(e.message, { cause: e });
      } else if (typeof e === 'string') {
        throw new Error(e);
      }
    }
  };

  useEffect(() => {
    setImages([]);
    setLocalUrls([]);
  }, []);

  return (
    <>
      <TermsAndConditions />
      <NavigationBar pageCount="1/4" />
      <div className="flex-column section-small">
        <div className="flex-column section-small">
          <span className="tag-headline">Upload personal images</span>
          <div className="flex-column">
            <h1 className="title">Choose 1-3 images</h1>
            <h2 className="subtitle">add by clicking the +</h2>
          </div>
          {error && (
            <div className="error-message">{error} Please try again.</div>
          )}
        </div>

        {!loadState ? (
          <UploadArea setSelectedFiles={handleFilesSelected} />
        ) : (
          <Loader />
        )}
      </div>

      {images && images?.length > 0 && !loadState ? (
        <div className="bottom-page-overlay">
          <button
            type="button"
            onClick={() => {
              onUpload()
                .then(() => navigate('results'))
                .catch((e) => {
                  setLoadState(false);
                  setError(e.message);
                });
            }}
            className="btn"
          >
            Analyze images
          </button>
        </div>
      ) : (
        <div className="bottom-page-overlay">
          <div className="btn disabled">Analyze images</div>
        </div>
      )}
    </>
  );
};

export default Upload;
