import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import NavigationBar from '../../components/NavigationBar';
import InputField from '../../components/InputField';
import Bottle from '../../components/Bottle';
import { EventFormContext } from '../../contexts/EventFormContext';

type Props = {
  windowWidth: number;
  isEvent?: boolean;
};

function BottlePreview({ windowWidth }: Props) {
  const [perfumeNameIsChosen, setPerfumeNameIsChosen] = useState(false);
  const navigate = useNavigate();
  const { setPersonName, setPerfumeName, personName, perfumeName } = useContext(EventFormContext);

  function capitalize(input: string) {
    if (!input) return '';
    return input
      .split(' ')
      .map((word) => (word[0] ? word[0] + word.substring(1).toLowerCase() : ''))
      .join(' ');
  }

  useEffect(() => {
    document.querySelector('input')!.focus();
  }, []);

  return (
    <>
      <NavigationBar pageCount="3/4" />
      <div
        className={`${
          windowWidth >= 1024 ? 'grid-area-bottle' : 'display-contents'
        }`}
      >
        <Bottle
          customerName={personName}
          perfumeName={perfumeName}
        />
        <div className="flex-column section-small left-align bottle-input">
          <span className="tag-headline">Almost done! But first...</span>
          <AnimatePresence mode="wait">
            {perfumeNameIsChosen ? (
              <InputField
                key="customerName"
                windowWidth={windowWidth}
                text="...and your name"
                setter={setPersonName}
              />
            ) : (
              <InputField
                key="perfumeName"
                windowWidth={windowWidth}
                text="Perfume name"
                maxLength={12}
                setter={(input) => setPerfumeName(capitalize(input))}
              />
            )}
          </AnimatePresence>
        </div>
        {perfumeName?.length && perfumeNameIsChosen ? (
          <div
            style={windowWidth < 768 ? { padding: '0 16px 16px 16px' } : {}}
            className={`bottle-button ${
              personName && perfumeNameIsChosen
                ? ''
                : 'disabled bottle-button'
            }`}
          >
            <button
              type="button"
              onClick={() => {
                if (personName?.length && perfumeNameIsChosen) {
                  navigate('../cart');
                  setPerfumeNameIsChosen(false);
                }
              }}
              className="btn bottle-button submit"
            >
              Continue
            </button>
          </div>
        ) : (
          <div
            style={windowWidth < 768 ? { padding: '0 16px 16px 16px' } : {}}
            className={` bottle-button ${
              perfumeName?.length ? '' : 'disabled'
            }`}
          >
            <button
              type="button"
              onClick={() => {
                if (perfumeName?.length) {
                  setPerfumeNameIsChosen(true);
                  document.querySelector('input')!.value = '';
                }
              }}
              className="btn  bottle-button submit"
            >
              Continue
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default BottlePreview;
