/**
 *
 * @param {String[]} baseTags Array of tags from base
 * @param {String[]} accordTags Array of tags from accords
 * @returns Array of all tags with duplicates removed
 */
export default function removeDuplicateTags(baseTags: string[], accordTags: string[]) {
  if(!baseTags) return []
  return [...baseTags.filter(tag => !accordTags.includes(tag)), ...accordTags]
}
