import { createTheme } from '@mui/material/styles';

export const BrandFormBox = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10vh',
  overflow: 'hidden',
} as const;

export const theme = createTheme({
  palette: {
    primary: {
      main: '#808080',
    },
    secondary: {
      main: '#2F7413',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginBottom: '2rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: '100%',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '20%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: 'black',
          color: 'white',
          ':hover': {
            backgroundColor: '#353535',
          },
        },
        outlined: {
          backgroundColor: 'black',
          color: 'white',
          ':hover': {
            backgroundColor: '#353535',
          },
        },
        text: {
          backgroundColor: '#black',
          color: 'white',
          ':hover': {
            backgroundColor: '#353535',
          },
        },

        root: {
          borderRadius: '2rem',
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'center',
          justifyContent: 'center',
          fontSize: '12px',
          fontWeight: 500,
          height: '3rem',
          letterSpacing: '0.02em',
          lineHeight: '120%',
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          marginBottom: '1rem',
          paddingLeft: '2rem',
          paddingRight: '2rem',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {},
      },
    },
  },
  typography: {
    h1: {
      fontFamily: 'PP Neue Montreal, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '45px',
      lineHeight: '110%',
      letterSpacing: '-0.01em',
      padding: '0',
      marginBottom: '2rem',
      textAlign: 'center',
    },
    h2: {
      fontFamily: 'PP Neue Montreal, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '36px',
      lineHeight: '110%',
      letterSpacing: '-0.01em',
      marginBottom: '1rem',
      textAlign: 'center',
    },
    h3: {
      fontFamily: 'Swear Display Medium, sans-serif',
    },
    subtitle1: {
      fontFamily: 'PP Neue Montreal, sans-serif',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '100%',
      letterSpacing: '-0.01em',
      marginBottom: '1rem',
      textAlign: 'center',
    },
    subtitle2: {
      fontFamily: 'PP Neue Montreal, sans-serif',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '100%',
      letterSpacing: '-0.01em',
      textAlign: 'center',
      textTransform: 'uppercase',
      marginBottom: '3rem',
      paddingBottom: '1rem',
    },
    body1: {
      fontFamily: 'PP Neue Montreal, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '-0.01em',
    },
  },
});
