import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Layout from './pages/Layout';
import Upload from './pages/event/Upload';
import Results from './pages/event/Results';
import Welcome from './pages/Welcome';
import BottlePreview from './pages/event/BottlePreview';
import Cart from './pages/event/Cart';
import MixingBlends from './pages/event/MixingBlends';
import { orderTemplate } from './util/order';
import Header from './components/Header';
import { debounce } from './util/util';
import Success from './pages/event/Success';
import Error from './pages/Error';
import { BrandFormProvider } from './contexts/BrandFormContext';
import BrandStart from './pages/brand/BrandStart';
import BrandPersonName from './pages/brand/BrandPersonName';
import BrandName from './pages/brand/BrandName';
import BrandCore from './pages/brand/BrandCore';
import BrandEmotions from './pages/brand/BrandEmotions';
import BrandTime from './pages/brand/BrandTime';
import BrandPhysical from './pages/brand/BrandPhysical';
import BrandColors from './pages/brand/BrandColors';
import BrandImages from './pages/brand/BrandImages';
import BrandEmail from './pages/brand/BrandEmail';
import BrandPersonality from './pages/brand/BrandPersonality';
import BrandThanks from './pages/brand/BrandThanks';
import { ThemeProvider } from '@mui/material';
import { theme } from './scss/theme';
import { EventFormProvider } from './contexts/EventFormContext';

type Orientation = 'PORTRAIT' | 'LANDSCAPE';

function getCachedOrder() {
  const cached = window.sessionStorage.getItem('order');
  if (typeof cached === 'string') {
    return JSON.parse(cached);
  }
  return { ...orderTemplate };
}

function App() {
  const [order, setOrder] = useState(getCachedOrder());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [orientation, setOrientation]: [
    Orientation,
    Dispatch<SetStateAction<Orientation>>
  ] = useState(() => {
    return window.innerWidth < window.innerHeight
      ? ('PORTRAIT' as Orientation)
      : ('LANDSCAPE' as Orientation);
  });

  const location = useLocation();

  function updateDimensions() {
    const width = window.innerWidth;
    setWindowWidth(width);
    setOrientation(() =>
      width < window.innerHeight ? 'PORTRAIT' : 'LANDSCAPE'
    );
  }

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', debounce(updateDimensions));
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem('order', JSON.stringify(order));
  }, [order]);

  return (
    <div className="app">
      <AnimatePresence initial={false} mode="wait">
        <ThemeProvider theme={theme}>
          <BrandFormProvider>
            <EventFormProvider>
              <Routes key={location.pathname} location={location}>
                <Route path="/" element={<Layout header={<Header />} />}>
                  <Route path="/" element={<Welcome />} />
                  <Route path="brandscent/*">
                    <Route path="start" element={<BrandStart />} />
                    <Route path="personname" element={<BrandPersonName />} />
                    <Route path="name" element={<BrandName />} />
                    <Route path="core" element={<BrandCore />} />
                    <Route path="emotions" element={<BrandEmotions />} />
                    <Route path="personality" element={<BrandPersonality />} />
                    <Route path="time" element={<BrandTime />} />
                    <Route path="physical" element={<BrandPhysical />} />
                    <Route path="colors" element={<BrandColors />} />
                    <Route path="images" element={<BrandImages />} />
                    <Route path="email" element={<BrandEmail />} />
                    <Route path="success" element={<BrandThanks />} />
                  </Route>

                  <Route
                    path="event"
                  >
                    <Route path="" element={<Upload />}></Route>
                    <Route
                      path="mixingblends"
                      element={
                        <MixingBlends
                          windowWidth={windowWidth}
                          setOrder={setOrder}
                        />
                      }
                    />
                    <Route
                      path="results"
                      element={<Results windowWidth={windowWidth} />}
                    />
                    <Route
                      path="bottlepreview"
                      element={
                        <BottlePreview
                          windowWidth={windowWidth}
                        />
                      }
                    />
                    <Route
                      path="cart"
                      element={
                        <Cart
                          windowWidth={windowWidth}
                        />
                      }
                    />
                    <Route path="success" element={<Success />} />
                  </Route>
                </Route>
                <Route path="error" element={<Error />} />
                <Route path="*" element={<Error />} />
              </Routes>
            </EventFormProvider>
          </BrandFormProvider>
        </ThemeProvider>
      </AnimatePresence>
    </div>
  );
}

export default App;
