import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import {
  BrandFormContext,
  IBrandFormContext,
} from '../../contexts/BrandFormContext';
import InfoIcon from '@mui/icons-material/Info';
import { BrandFormBox } from '../../scss/theme';

const BrandPhysical: React.FC = () => {
  const navigate = useNavigate();
  const { physical, setPhysical } =
    useContext<IBrandFormContext>(BrandFormContext);
  const availableChoices = ['Thinking', 'Physical'];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhysical(event.target.value);
  };

  const handleSubmit = () => {
    navigate('/brandscent/colors');
  };

  return (
    <Container maxWidth="md">
      <Box sx={BrandFormBox}>
        <form onSubmit={handleSubmit} className="brand-form">
          <Typography variant="h2" gutterBottom>
            Is Your Brand Driven by Internal Insight (Thinking) or External
            Presence (Physical)?
            <Tooltip
              title={
                <div>
                  <Typography variant="body1">
                    Brand Positioning: Thinking vs. Physical
                  </Typography>
                  <p>
                    When evaluating your brand's identity, consider whether it
                    aligns more with Thinking (Internal) or Physical (External)
                    attributes:
                  </p>
                  <p>
                    Thinking (Internal): This perspective focuses on the
                    intellectual and emotional aspects of your brand, including
                    values, beliefs, and mission. Brands in this category
                    prioritize thought leadership, storytelling, and building
                    deeper connections with customers.
                  </p>
                  <p>
                    Physical (External): This view centers on the tangible
                    elements of your brand, such as product design, packaging,
                    and visual presence. Brands here emphasize quality,
                    functionality, and aesthetics, showcasing how their
                    offerings stand out in the marketplace.
                  </p>
                  <p>
                    Identifying whether your brand is more Thinking or Physical
                    can provide valuable insights into your positioning and
                    values, guiding strategic decisions that resonate with your
                    audience.
                  </p>
                </div>
              }
            >
              <InfoIcon />
            </Tooltip>
          </Typography>
          <Grid container spacing={2}>
            {availableChoices.map((item) => (
              <Grid item xs={12} sm={6} key={item}>
                <RadioGroup value={physical}>
                  <FormControlLabel
                    control={
                      <Radio value={item} onChange={handleChange} name={item} />
                    }
                    label={item}
                  />
                </RadioGroup>
              </Grid>
            ))}
          </Grid>
          <Button
            type="submit"
            variant="contained"
            disabled={!physical}
            color="primary"
            style={{ marginTop: '50px' }}
          >
            Continue
          </Button>
        </form>
      </Box>
    </Container>
  );
};
export default BrandPhysical;
