import { createContext, ReactNode, useState } from 'react';
import axios from 'axios';
import {
  checkIfUserExistsInPipedrive,
  createUserInPipedrive,
  uploadFilesToPipedrive,
} from '../api/pipedrive-api';

interface EventFormContext {
  personName: string;
  fullName: string;
  companyName: string;
  perfumeName: string;
  images: Blob[];
  imageUrlsToCloudinary: string[];
  email: string;
  recipe: string;
  characteristics: Record<string, number>;
  localUrls: string[];
  remoteUrls: string;
  tags: string[];
  setPersonName: (name: string) => void;
  setCompanyName: (name: string) => void;
  setPerfumeName: (name: string) => void;
  setFullName: (name: string) => void;
  setImages: (images: Blob[]) => void;
  setImageUrlsToCloudinary: (imageUrls: string[]) => void;
  setEmail: (email: string) => void;
  setRecipe: (recipe: string) => void;
  setCharacteristics: (characteristics: Record<string, number>) => void;
  setLocalUrls: (urls: string[]) => void;
  setRemoteUrls: (urls: string) => void;
  setTags: (tags: string[]) => void;
  submitData: () => Promise<void>;
}

const EventFormContext = createContext<EventFormContext>({
  personName: '',
  fullName: '',
  companyName: '',
  perfumeName: '',
  images: [],
  imageUrlsToCloudinary: [],
  email: '',
  recipe: '',
  characteristics: {},
  localUrls: [],
  remoteUrls: '',
  tags: [],
  setPersonName: () => {},
  setCompanyName: () => {},
  setPerfumeName: () => {},
  setFullName: () => {},
  setImages: () => {},
  setImageUrlsToCloudinary: () => {},
  setEmail: () => {},
  setRecipe: () => {},
  setCharacteristics: () => {},
  submitData: async () => {},
  setLocalUrls: () => {},
  setRemoteUrls: () => {},
  setTags: () => {},
});
const EventFormProvider = ({ children }: { children: ReactNode }) => {
  const [personName, setPersonName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [perfumeName, setPerfumeName] = useState('');
  const [fullName, setFullName] = useState('');
  const [imageUrlsToCloudinary, setImageUrlsToCloudinary] = useState<string[]>(
    []
  );
  const [images, setImages] = useState<Blob[]>([]);
  const [email, setEmail] = useState('');
  const [recipe, setRecipe] = useState('');
  const [characteristics, setCharacteristics] = useState<
    Record<string, number>
  >({});
  const [localUrls, setLocalUrls] = useState<string[]>([]);
  const [remoteUrls, setRemoteUrls] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  const submitData = async () => {
    // Check if user exists in Pipedrive, if not create a new user to add to the deal
    let userId = await checkIfUserExistsInPipedrive(email);
    if (!userId) {
      userId = await createUserInPipedrive(personName, email);
    }

    // Create a new deal in Pipedrive, and afterwards upload the images to the deal
    try {
      const data = {
        title: 'Event - ' + companyName,
        person_id: userId,
        imageUrlsToCloudinary: remoteUrls,
        recipe: JSON.stringify(recipe),
      };
      const { data: dealId } = await axios.post(
        'https://no-ordinary-scent-api.herokuapp.com/v1/create-pipedrive-deal',
        data
      );
      await uploadFilesToPipedrive(images, dealId);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <EventFormContext.Provider
      value={{
        personName,
        fullName,
        companyName,
        perfumeName,
        images,
        imageUrlsToCloudinary,
        email,
        recipe,
        characteristics,
        localUrls,
        remoteUrls,
        tags,
        setPersonName,
        setCompanyName,
        setPerfumeName,
        setFullName,
        setImages,
        setImageUrlsToCloudinary,
        setEmail,
        setRecipe,
        setCharacteristics,
        submitData,
        setLocalUrls,
        setRemoteUrls,
        setTags,
      }}
    >
      {children}
    </EventFormContext.Provider>
  );
};

export { EventFormContext, EventFormProvider };
