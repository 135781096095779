import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import {
  BrandFormContext,
  IBrandFormContext,
} from '../../contexts/BrandFormContext';
import { ICheckedItems } from '../../types/types';
import { BrandFormBox } from '../../scss/theme';

const BrandColors: React.FC = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const { setColors } = useContext<IBrandFormContext>(BrandFormContext);
  const availableColors = [
    'Black',
    'White',
    'Grey',
    'Neutrals',
    'Colorful',
    'Blue',
    'Green',
    'Red',
    'Pink',
    'Golden',
    'Silver',
    'Other',
  ];
  const [showNewColorInput, setShowNewColorInput] = useState(false);
  const [newColor, setNewColor] = useState('');
  const [checkedItems, setCheckedItems] = useState<ICheckedItems>(
    availableColors.reduce((color, item) => ({ ...color, [item]: false }), {})
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (Object.values(checkedItems).filter((i) => i).length < 2) {
        setShowError(false);
        setCheckedItems({
          ...checkedItems,
          [event.target.name]: event.target.checked,
        });
        if (event.target.name === 'Other') {
          setShowNewColorInput(true);
        }
      } else {
        setShowError(true);
      }
    } else {
      setCheckedItems({
        ...checkedItems,
        [event.target.name]: event.target.checked,
      });
      if (event.target.name === 'Other') {
        setShowNewColorInput(false);
        setNewColor('');
      }
      setShowError(false);
    }
  };

  const handleSubmit = () => {
    const colors = Object.keys(checkedItems).filter((key) =>
      checkedItems[key].valueOf()
    );
    if (colors.includes('Other')) {
      colors[colors.indexOf('Other')] = newColor;
    }
    setColors(colors);
    navigate('/brandscent/images');
  };

  return (
    <Container maxWidth="md">
      <Box sx={BrandFormBox}>
        <form onSubmit={handleSubmit} className="brand-form">
          <Typography variant="subtitle2" gutterBottom>
            THE POWER OF COLOR IN BRAND COMMUNICATION
          </Typography>
          <Typography variant="h2" gutterBottom>
            What colors best convey the essence of your brand?
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Please select
            <b> two </b>
            that resonate most strongly with your identity.
          </Typography>
          <Grid container spacing={2}>
            {availableColors.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems[item]}
                      onChange={handleChange}
                      name={item}
                    />
                  }
                  label={item}
                />
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '2rem',
            }}
          >
            {showNewColorInput && (
              <FormGroup>
                <TextField
                  label="Color"
                  margin="normal"
                  onChange={(e) =>
                    setNewColor((e.target as HTMLInputElement).value)
                  }
                />
              </FormGroup>
            )}
          </Box>
          {showError && <Alert severity="error">Select maximum 2 colors</Alert>}

          <Button
            type="submit"
            variant="contained"
            disabled={
              Object.values(checkedItems).filter((i) => i).length !== 2 ||
              (checkedItems['Other'] && !newColor)
            }
            color="primary"
            style={{ marginTop: '20px' }}
          >
            Continue
          </Button>
        </form>
      </Box>
    </Container>
  );
};
export default BrandColors;
