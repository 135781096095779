import React from 'react';
import { useNavigate } from 'react-router-dom';
import leftArrow from '../assets/icons/leftArrow.svg';

type Props = { pageCount: string }

function NavigationBar({ pageCount }: Props) {
  const navigate = useNavigate();
  return (
    <div>
      <div style={{ width: '100vw', borderTop: '1px solid black' }} />
      <div className='navbar'>
        <button
          style={{ border: 0, background: 'transparent', cursor: 'pointer' }}
          type='button'
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={leftArrow} alt='Arrow pointing left' />
        </button>
        {pageCount &&
          <div className='page-count'>{pageCount}</div>}
      </div>
    </div>
  );
}

export default NavigationBar;
