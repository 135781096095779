import React, { useState } from 'react';

function TermsAndConditions() {
  const [acceptedTerms, setAcceptedTerms] = useState(
    window.sessionStorage.getItem('acceptedTerms')
  );

  return (
    <div
      className="page-overlay"
      style={acceptedTerms ? { display: 'none' } : {}}
    >
      <p>
        Let’s create something unique!
        <br /> But first, in order to proceed you need to accept our{' '}
        <a href="https://nosworkshop.se/policies/privacy-policy">
          privacy policy
        </a>{' '}
        and{' '}
        <a href="https://nosworkshop.se/policies/terms-of-service">
          terms and conditions
        </a>
        . We care about your privacy, and your personal information, including
        images, will never be shared publicly.
      </p>
      <button
        onClick={() => {
          setAcceptedTerms('true');
          window.sessionStorage.setItem('acceptedTerms', 'true');
        }}
        type="button"
        className="btn"
      >
        I accept
      </button>
    </div>
  );
}

export default TermsAndConditions;
