import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import { animateBlends, fadeItem } from '../../util/animations';
import { getBlendsInStock } from '../../api/api';

const shortDivs = [0, 3, 4, 7, 8, 11];

/**
 *
 * @param {boolean} isBaseChosen
 * @returns The introductory text explaining what the user should do in the current step.
 */
function Intro({ isBaseChosen }) {
  return (
    <>
      <NavigationBar pageCount={`${isBaseChosen ? '2' : '1'}/5`} />

      <div className="flex-column section-small">
        <span className="tag-headline">Mixing Blends</span>
        <div className="flex-column">
          <h1 className="title">{`Choose ${
            isBaseChosen ? '1 accord' : '1 base'
          }`}</h1>
          <h2 className="subtitle">
            to use as {isBaseChosen ? 'characteristics' : 'foundation'}
          </h2>
        </div>
      </div>
      <p>
        {isBaseChosen
          ? 'Choose one accord that will act like characteristic scent upon your base. Each accord has an olfactive family which is where it origins. The notes are the characters that makes the accord unique. Take your time and follow your gut!'
          : 'The base will be the foundation of your unique scent. Every base has an olfactive family which is where it origins. The notes are the characteristics that makes the base unique. Get to know each base and follow your intuition!'}
      </p>
    </>
  );
}

function Accords({
  matchingAccordIDs,
  accords,
  setSelectedAccords,
  selectedAccords,
}) {
  function handleAccordSelection(e, accord) {
    let card = e.target;

    while (!card.parentNode.classList.contains('card__grid')) {
      card = card.parentNode;
    }

    if (
      selectedAccords.length < 1 &&
      !card.classList.contains('selectedCard')
    ) {
      card.classList.add('selectedCard');
      setSelectedAccords((prev) => [...prev, accord]);
    } else if (card.classList.contains('selectedCard')) {
      card.classList.remove('selectedCard');
      setSelectedAccords((prev) => prev.filter((id) => id !== accord));
    }
  }

  return (
    <AnimatePresence>
      <motion.div
        variants={animateBlends}
        initial="hidden"
        animate="show"
        className="card__grid"
        style={selectedAccords.length ? { paddingBottom: '96px' } : {}}
      >
        {matchingAccordIDs.length &&
          Object.keys(accords)
            /*             .filter((acc) => matchingAccordIDs.includes(acc)) */
            .map((accord, index) => (
              <motion.div
                variants={fadeItem}
                key={accords[accord].blendName}
                onClick={(e) => handleAccordSelection(e, accord)}
                role="button"
                className={shortDivs.includes(index) ? 'short' : 'tall'}
              >
                <motion.div className="image__wrapper">
                  <motion.img
                    src={accords[accord].imageFile[0].thumbnails.large.url}
                    alt="test"
                  />
                </motion.div>
                <motion.div className="blend__info">
                  <span
                    key={accords[accord].alternativeBlendName}
                    className="blend__name"
                  >
                    {accords[accord].alternativeBlendName}
                  </span>
                  <span className="blend__description">
                    Olfactive family: {accords[accord]['olfactive Family']}
                  </span>
                  <span>
                    Notes: {accords[accord].heartDisplayName},{' '}
                    {accords[accord].topDisplayName}
                  </span>
                </motion.div>
              </motion.div>
            ))}
      </motion.div>
    </AnimatePresence>
  );
}

function MixingBlends({ windowWidth, setOrder }) {
  const [selectedBase, setSelectedBase] = useState([]);
  const [selectedAccords, setSelectedAccords] = useState([]);
  const [bases, setBases] = useState();
  const [accords, setAccords] = useState();
  const [isBaseChosen, setIsBaseChosen] = useState(false);
  const [matchingAccordIDs, setMatchingAccordIDs] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    setOrder((prev) => {
      const order = {};
      for (const prop in prev) {
        if (!prop.includes('_image')) order[prop] = prev[prop];
      }
      return order;
    });
  }, []);

  function cardSize(index) {
    if (index < 4) {
      return index % 2 === 0 ? 'short' : 'tall';
    }
    return 'short';
  }

  function completeMixing() {
    const characteristics = {
      ambery: 0,
      citrus: 0,
      green: 0,
      fruity: 0,
      gourmand: 0,
      musk: 0,
      woody: 0,
      floral: 0,
      leathery: 0,
      marine: 0,
      spicy: 0,
    };

    Object.keys(characteristics).forEach((key) => {
      characteristics[key] = bases[selectedBase][key] || 0;
      selectedAccords.forEach((acc) => {
        characteristics[key] += accords[acc][key] || 0;
      });
    });

    let total = 0;
    Object.values(characteristics).forEach((val) => {
      total += val;
    });
    Object.keys(characteristics).forEach((key) => {
      characteristics[key] /= total;
    });

    let tags = [];
    bases[selectedBase].tags.forEach((t) => tags.push(t));
    /*     console.log(bases[selectedBase]); */

    const recipeData = {
      mix: {
        base: {
          id: bases[selectedBase].blendID,
          displayName: bases[selectedBase].baseDisplayName,
        },
        heart: { id: accords[selectedAccords[0]].blendID },
        top: { id: accords[selectedAccords[0]].blendID },
      },
    };
    let heartD = '';
    let topDisplayName = '';

    selectedAccords.forEach((acc) => {
      accords[acc].tags
        .filter((t) => !tags.includes(t))
        .forEach((t) => tags.push(t));
      heartD += `${JSON.stringify(accords[acc].heartDisplayName)}, `;
      topDisplayName += `${JSON.stringify(accords[acc].topDisplayName)}, `;
    });

    recipeData.mix.heart.displayName = heartD.replace(/"/g, ' ');
    recipeData.mix.top.displayName = topDisplayName.replace(/"/g, ' ');
    window.sessionStorage.setItem('recipeData', JSON.stringify(recipeData));

    let recipeString = '';
    selectedAccords.forEach((accord) => {
      recipeString += `${accord}, `;
    });
    recipeString += selectedBase;
    setOrder((prev) => ({ ...prev, recipe: recipeString, characteristics }));

    navigate('/results', {
      state: {
        referrer: 'mixingblends',
      },
    });
  }

  function handleImageClick(e, base) {
    let card = e.target;

    while (!card.parentNode.classList.contains('card__grid')) {
      card = card.parentNode;
    }

    if (selectedBase.length === 0 && !card.classList.contains('selectedCard')) {
      card.classList.add('selectedCard');
      setSelectedBase(base);
      setMatchingAccordIDs(bases[base].matchingAccordIDs);
      setTimeout(() => setIsBaseChosen(true), 200);
    }
  }

  function keysToLowercase(object) {
    const tmp = {};
    Object.keys(object).forEach((base) => {
      tmp[base] = {};
      Object.keys(object[base]).forEach((key) => {
        tmp[base][key.replace(/^./, key.charAt(0).toLowerCase())] =
          object[base][key];
      });
    });
    return tmp;
  }

  useEffect(() => {
    getBlendsInStock().then((data) => {
      setBases(keysToLowercase(data.base));
      setAccords(keysToLowercase(data.accord));
    });
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, [isBaseChosen]);

  return (
    <div className="container__mixingblends">
      <Intro isBaseChosen={isBaseChosen} />

      <AnimatePresence>
        {!isBaseChosen && bases && (
          <motion.div
            variants={animateBlends}
            initial="hidden"
            animate="show"
            className="card__grid"
            style={selectedBase.length ? { paddingBottom: '96px' } : {}}
          >
            {Object.keys(bases).map((base, index) => (
              <motion.div
                variants={fadeItem}
                key={bases[base].blendName}
                onClick={(e) => handleImageClick(e, base)}
                role="button"
                className={
                  windowWidth < 1366
                    ? shortDivs.includes(index)
                      ? 'short'
                      : 'tall'
                    : cardSize(index)
                }
              >
                <motion.div className="image__wrapper">
                  <motion.img
                    src={bases[base].imageFile[0].thumbnails.large.url}
                    alt="test"
                  />
                </motion.div>
                <motion.div className="blend__info">
                  <span
                    key={bases[base].alternativeBlendName}
                    className="blend__name"
                  >
                    {bases[base].alternativeBlendName}
                  </span>
                  <span className="blend__description">
                    Olfactive family: {bases[base]['olfactive Family']}
                  </span>
                  <span>Notes: {bases[base].baseDisplayName}</span>
                </motion.div>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      {isBaseChosen && (
        <>
          <Accords
            matchingAccordIDs={matchingAccordIDs}
            base={selectedBase}
            accords={accords}
            setSelectedAccords={setSelectedAccords}
            selectedAccords={selectedAccords}
          />
          <div
            className={`bottom-page-overlay ${
              selectedAccords.length ? '' : 'hidden'
            }`}
          >
            <button
              type="button"
              disabled={selectedAccords.length === 0}
              onClick={completeMixing}
              style={
                windowWidth <= 768 ? { width: '100%' } : { alignSelf: 'normal' }
              }
              className="btn"
            >
              View results
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default MixingBlends;
