export type Characteristics = {
  [index: string]: number
}

export function topSixCategories(obj: Characteristics) {
  const sortedObject: Characteristics = {};

  const sortedArrayFromObject = Object.entries(obj).sort((a, b) => b[1] - a[1]);
  const topSix = sortedArrayFromObject.slice(0,6)
  const sum = topSix.map(([,v]) => v).reduce((a, b) => a+b, 0);

  sortedArrayFromObject.forEach(([key, value]) => {
    if (value !== 0 && Object.keys(sortedObject).length < 6) {
      sortedObject[key] = value/sum;
    }
  });
  return sortedObject;
}
