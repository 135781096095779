import axios from 'axios';
import { Order } from '../util/order';
import { Recipe } from '../contexts/BrandFormContext';

const NOS_API_BASE_URL = 'https://no-ordinary-scent-api.herokuapp.com';

//const LOCAL_RECIPE_SERVER = 'http://127.0.0.1:4000'

export async function getOlfactives() {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await fetch(`${NOS_API_BASE_URL}/v1/get-olfactive-families`);
      const json = await data.json();
      resolve(json);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

export async function getBlendsInStock() {
  return new Promise((resolve, reject) => {
    axios(`${NOS_API_BASE_URL}/v1/get-accord-index`)
      .then((response) => {
        const { data } = response;
        return resolve(data);
      })
      .catch(reject);
  });
}

export type RecipeData = {
  accordTags: string[];
  baseTags: string[];
  characteristics: { [index: string]: number };
  mix: {
    [index: string]: { id: string; displayName: string; description: string };
  };
  recipe: string;
  type: string;
};

export const getRecipe = async (
  records: { _url: string }[]
): Promise<RecipeData> => {
  return new Promise<RecipeData>((resolve, reject) => {
    const recipeRequest = {
      url: `${NOS_API_BASE_URL}/v1/create_recipe`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ records }),
    };

    axios(recipeRequest)
      .then((res) => resolve(res.data.recipeData))
      .catch((err) => reject(err.statusText));
  });
};

export const getB2BRecipe = async (
  records: { _url: string }[]
): Promise<Recipe> => {
  return new Promise<Recipe>((resolve, reject) => {
    const recipeRequest = {
      url: `${NOS_API_BASE_URL}/v1/create-b2b-recipe`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ records }),
    };

    axios(recipeRequest)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.statusText));
  });
};

export const placeShopifyOrder = async (order: Order & {companyName: string; fullName: string;}) => {
  const orderRequest = {
    url: `${NOS_API_BASE_URL}/v1/place_order`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      ...order,
      madeBy: order.customerName,
      companyName: order.companyName,
      fullName: order.fullName
    }),
  };
  return axios(orderRequest);
};
