import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo-small.svg';
import { BrandFormBox } from '../../scss/theme';

function BrandStart() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={BrandFormBox}>
        <img
          src={Logo}
          alt="NOS Emotiontech logo"
          style={{ width: '120px', marginBottom: '20px' }}
        />
        <Typography variant="h1">
          Welcome to AI-Driven Scent Creation
        </Typography>
        <Typography variant="subtitle1">
          Unlock your brand's unique fragrance, tailored through the power of
          EmotiontechTM.
        </Typography>
        <Typography variant="subtitle1">
          Begin by answering a few targeted questions about your brand's
          identity. You'll then have the option to upload images that best
          capture your brand's essence. Let's create something extraordinary! 🚀
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={() => navigate('/brandscent/personname/')}
        >
          Start
        </Button>
      </Box>
    </Container>
  );
}

export default BrandStart;
